var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-footer',{attrs:{"fixed":"","padless":""}},[_c('v-card',{staticClass:"text-center",attrs:{"flat":"","tile":"","width":"100%","color":_vm.color},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c('v-card-actions',{staticClass:"d-flex justify-center"},[_c('v-slide-group',{attrs:{"show-arrows":"","center-active":""}},[_c('v-slide-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var toggle = ref.toggle;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-4",attrs:{"aria-label":"icono teléfono","link":"","icon":"","color":"ico4","href":"tel:635507429","target":"_blank"},on:{"click":toggle}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"24px"}},[_vm._v(" mdi-phone ")])],1)]}}],null,true)},[_c('span',[_vm._v("Llamar por teléfono")])])]}}])}),_c('v-slide-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var toggle = ref.toggle;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-4",attrs:{"aria-label":"icono descargar CV","link":"","icon":"","color":"ico3","role":"link","href":"/files/cv.pdf","target":"_blank","loading":_vm.loading,"disabled":_vm.loading},on:{"click":function($event){_vm.loader = 'loading'; toggle()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"24px"}},[_vm._v(" mdi-download ")])],1)]}}],null,true)},[_c('span',[_vm._v("Descargar Cv.pdf")])])]}}])}),_c('v-slide-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var toggle = ref.toggle;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-4",attrs:{"aria-label":"icono Github","link":"","icon":"","color":"ico2","href":"https://github.com/AlbertoCF88","target":"_blank","rel":"noreferrer"},on:{"click":toggle}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"24px"}},[_vm._v(" mdi-github ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ir a Github")])])]}}])}),_c('v-slide-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var toggle = ref.toggle;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-4",attrs:{"aria-label":"icono correo","link":"","icon":"","color":"ico1","href":"mailto: alcafefront@gmail.com","target":"_blank","rel":"noreferrer"},on:{"click":toggle}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"24px"}},[_vm._v(" mdi-at ")])],1)]}}],null,true)},[_c('span',[_vm._v("Escribir correo")])])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }